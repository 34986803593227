import { IS_PREVIEW } from '@src/typescriptGlobals/constants';
import { GQL_PAGE_ID_TYPES } from '@src/typescriptGlobals/contentful.types';

import { GQL_FETCH_HOME_MEET_OUR_CLIENT_ENTRY, GQL_FETCH_HOME_PAGE_CLIENT_SUCCESS_ENTRY, GQL_FETCH_HOME_PAGE_CTA_ENTRY, GQL_FETCH_HOME_PAGE_DATA_INFORMED_ENTRY, GQL_FETCH_HOME_PAGE_HERO_ENTRY_ENTRY, GQL_FETCH_HOME_PAGE_IMAGE_GRID_ENTRY, GQL_FETCH_HOME_PAGE_SITE_OPTIONS_ENTRY, GQL_FETCH_HOME_PAGE_SOLUTIONS_ENTRY, GQL_FETCH_HOME_PAGE_WE_EXIST_ENTRY } from './pageQueries/home/homePageQueries';
import { GQL_FETCH_SOLUTIONS_PAGE_BETTER_MANAGEMENT_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_CLIENT_SUCCESS_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_CLINICIANS_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_CTA_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_DATA_BEYOND_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_HERO_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_LIKE_PROS_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_MEASUREMENT_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_NORMATIVE_DATA_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_PATIENTS_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_TESTIMONIAL_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_TESTIMONIALS_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_VALD_HUB_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_WE_EXIST_ENTRY, GQL_FETCH_SOLUTIONS_PAGE_WE_EXIST_V2_ENTRY } from './pageQueries/solutions/solutionsPageQueries';
import { GQL_FETCH_CLINICIANS_PAGE_CLIENT_SUCCESS_ENTRY, GQL_FETCH_CLINICIANS_PAGE_CLINICAL_SETTING_CAROUSEL_ENTRY, GQL_FETCH_CLINICIANS_PAGE_CLINICAL_SETTING_ENTRY, GQL_FETCH_CLINICIANS_PAGE_CTA_ENTRY, GQL_FETCH_CLINICIANS_PAGE_FOR_CLINICIANS_ENTRY, GQL_FETCH_CLINICIANS_PAGE_HERO_ENTRY, GQL_FETCH_CLINICIANS_PAGE_MORE_TIME_ENTRY, GQL_FETCH_CLINICIANS_PAGE_TAILORED_CARE_ENTRY, GQL_FETCH_CLINICIANS_PAGE_WE_EXIST_ENTRY } from './pageQueries/clinicians/cliniciansPageQueries';
import { GQL_FETCH_PATHOLOGIES_PAGE_BENCHMARK_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_CARE_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_CLIENT_SUCCESS, GQL_FETCH_PATHOLOGIES_PAGE_CONVALESCE_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_CTA_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_HERO_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_MSK_CONDITIONS_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_ORTHOPAEDICS_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_PHONE_MOCKUPS_ENTRY, GQL_FETCH_PATHOLOGIES_PAGE_WE_EXIST_ENTRY } from './pageQueries/pathologies/pathologiesPageQueries';
import { GQL_FETCH_PRODUCTS_PAGE_BETTER_COMMUNICATION_ENTRY, GQL_FETCH_PRODUCTS_PAGE_BFR_CUFFS_ENTRY, GQL_FETCH_PRODUCTS_PAGE_CLIENT_SUCCESS, GQL_FETCH_PRODUCTS_PAGE_CTA_ENTRY, GQL_FETCH_PRODUCTS_PAGE_DATA_POINTS_ENTRY, GQL_FETCH_PRODUCTS_PAGE_EFFECTIVE_CARE_ENTRY, GQL_FETCH_PRODUCTS_PAGE_HERO_ENTRY, GQL_FETCH_PRODUCTS_PAGE_MORE_TIME_FOR_PATIENTS_ENTRY, GQL_FETCH_PRODUCTS_PAGE_NORMATIVE_DATA_ENTRY, GQL_FETCH_PRODUCTS_PAGE_OBJECTIVE_MEASUREMENT_ENTRY, GQL_FETCH_PRODUCTS_PAGE_OBJECTIVE_MEASUREMENT_SOLUTIONS_ENTRY, GQL_FETCH_PRODUCTS_PAGE_RELATED_PRODUCTS_2ND_ENTRY, GQL_FETCH_PRODUCTS_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_PRODUCTS_PAGE_REPORTING_ENTRY, GQL_FETCH_PRODUCTS_PAGE_TOOLKIT_ENTRY, GQL_FETCH_PRODUCTS_PAGE_WE_EXIST_ENTRY } from './pageQueries/productsLandingPage/productsPageQueries';
import { GQL_FETCH_AIRBANDS_PAGE_BFR_TRAINING_ENTRY, GQL_FETCH_AIRBANDS_PAGE_CONVENIENCE_ENTRY, GQL_FETCH_AIRBANDS_PAGE_CTA_ENTRY, GQL_FETCH_AIRBANDS_PAGE_FULLY_FEATURED_ENTRY, GQL_FETCH_AIRBANDS_PAGE_HERO_ENTRY, GQL_FETCH_AIRBANDS_PAGE_RELATED_ENTRY, GQL_FETCH_AIRBANDS_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_AIRBANDS_PAGE_TAKEAWAY_GUESSWORK_ENTRY, GQL_FETCH_AIRBANDS_PAGE_WHY_BFR_ENTRY } from './pageQueries/products/airBands/airBandsPageQueries';
import { GQL_FETCH_DYNAMO_PAGE_BANNER_TEXT_ENTRY, GQL_FETCH_DYNAMO_PAGE_CTA_ENTRY, GQL_FETCH_DYNAMO_PAGE_DYNAMO_MAX_ENTRY, GQL_FETCH_DYNAMO_PAGE_ENGAGEMENT_ENTRY, GQL_FETCH_DYNAMO_PAGE_FLEXIBILITY_ENTRY, GQL_FETCH_DYNAMO_PAGE_LIGHT_ENTRY, GQL_FETCH_DYNAMO_PAGE_MODULARITY_ENTRY, GQL_FETCH_DYNAMO_PAGE_PLUS_ENTRY, GQL_FETCH_DYNAMO_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_DYNAMO_PAGE_RELATED_SYSTEMS_ENTRY, GQL_FETCH_DYNAMO_PAGE_TEST_BATTERIES_ENTRY, GQL_FETCH_DYNAMO_PAGE_TESTS_ENTRY, GQL_FETCH_DYNAMO_PAGE_TWO_MODELS_ENTRY, GQL_FETCH_DYNAMO_PAGE_USEABILITY_ENTRY, GQL_FETCH_DYNAMO_PAGE_VISIBILITY_ENTRY } from './pageQueries/products/dynamo/dynamoPageQueries';
import { GQL_FETCH_FD_VALIDATED_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_BALANCE_ASSESSMENT_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_CTA_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_FIT_FOR_PURPOSE_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_FLEXIBILITY_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_HERO_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_MADE_EASY_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_USEABILITY_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_VERSATILITY_ENTRY, GQL_FETCH_FORCEDECKS_PAGE_VISION_ENTRY, GQL_FETCH_FORCEDECKS_RELATED_PRODUCTS_ENTRY, GQL_FETCH_FORCEDECKS_RELATED_SYSTEMS_ENTRY } from './pageQueries/products/forceDecks/forceDecksPageQueries';
import { GQL_FETCH_FORCEFRAME_PAGE_BANNER_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_CTA_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_FAQ_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_FEATURE_IMAGE_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_FLEXIBILITY_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_FLEXIBILITY_PATIENTS_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_FLEXIBLE_PAYMENT_PLAN_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_FOLD_CARDS_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_MAX_CARDS_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_MODULARITY_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_RELATED_SYSTEMS_ENTRY, GQL_FETCH_FORCEFRAME_PAGE_TEST_AND_TRAIN_ENTRY } from './pageQueries/products/forceFrame/forceFramePageQueries';
import { GQL_FETCH_HUMANTRAK_PAGE_ACCESSIBILITY_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_BANNER_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_CONTEXT_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_CTA_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_ENGAGEMENT_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_FLEXIBILITY_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_KIT_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_POWERFUL_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_RELATED_ENTRY, GQL_FETCH_HUMANTRAK_PAGE_RELATED_PRODUCTS_ENTRY } from './pageQueries/products/humanTrak/humanTrakPageQueries';
import { GQL_FETCH_MSKED_PAGE_130_TOPIC_ENTRY, GQL_FETCH_MSKED_PAGE_BANNER_ENTRY, GQL_FETCH_MSKED_PAGE_CTA_ENTRY, GQL_FETCH_MSKED_PAGE_EASY_TO_UNDERSTAND_ENTRY, GQL_FETCH_MSKED_PAGE_EDUCATION_DELIVERED_ENTRY, GQL_FETCH_MSKED_PAGE_PRESCRIBE_EDUCATIONAL_ENTRY, GQL_FETCH_MSKED_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_MSKED_PAGE_VALD_SYSTEMS_ENTRY } from './pageQueries/products/msked/mskedPageQueries';
import { GQL_FETCH_NORDBORD_PAGE_BANNER_ENTRY, GQL_FETCH_NORDBORD_PAGE_CTA_ENTRY, GQL_FETCH_NORDBORD_PAGE_EASY_ENTRY, GQL_FETCH_NORDBORD_PAGE_FEATURES_ENTRY, GQL_FETCH_NORDBORD_PAGE_FLEXIBILITY_ENTRY, GQL_FETCH_NORDBORD_PAGE_GOLD_STANDARD_ENTRY, GQL_FETCH_NORDBORD_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_NORDBORD_PAGE_RELATED_SYSTEMS_ENTRY, GQL_FETCH_NORDBORD_PAGE_TEST_ECCENTRIC_ENTRY, GQL_FETCH_NORDBORD_PAGE_USABILITY_ENTRY } from './pageQueries/products/nordBord/nordBordPageQueries';
import { GQL_FETCH_SCOREIT_PAGE_CONVENIENT_ENTRY, GQL_FETCH_SCOREIT_PAGE_CTA_ENTRY, GQL_FETCH_SCOREIT_PAGE_DELIVERED_ENTRY, GQL_FETCH_SCOREIT_PAGE_DIGITAL_PROMS_ENTRY, GQL_FETCH_SCOREIT_PAGE_HERO_ENTRY, GQL_FETCH_SCOREIT_PAGE_PROMS_LIBRARY_ENTRY, GQL_FETCH_SCOREIT_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_SCOREIT_PAGE_VALIDATED_ENTRY, GQL_FETCH_SCOREIT_PRODUCT_RELATED_SYSTEMS_ENTRY } from './pageQueries/products/scoreIt/scoreItPageQueries';
import { GQL_FETCH_SMARTSPEED_PAGE_ADDONS_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_CTA_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_DASH_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_DATA_ACCESSORIES_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_FLEXIBLE_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_GRID_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_HERO_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_PLUS_APP_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_RELIABLE_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_SHOWCASE_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_SMART_JUMP_ACCESSORIES_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_SMART_JUMP_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_SPEED_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_UP_IN_LIGHTS_ENTRY, GQL_FETCH_SMARTSPEED_PAGE_WIDE_RANGE_ENTRY } from './pageQueries/products/smartSpeed/smartSpeedPageQueries';
import { GQL_FETCH_PAGE_TELEHAB_PAGE_CTA_ENTRY, GQL_FETCH_TELEHAB_PAGE_ENGAGEMENT_ENTRY, GQL_FETCH_TELEHAB_PAGE_EXERCISE_LIBRARY_ENTRY, GQL_FETCH_TELEHAB_PAGE_HERO_ENTRY, GQL_FETCH_TELEHAB_PAGE_MONITORING_ENTRY, GQL_FETCH_TELEHAB_PAGE_PRESCRIPTION_ENTRY, GQL_FETCH_TELEHAB_PAGE_PROGRAM_BUILD_ENTRY, GQL_FETCH_TELEHAB_PAGE_RELATED_PRODUCTS_ENTRY, GQL_FETCH_TELEHAB_PAGE_RELATED_SYSTEMS_ENTRY } from './pageQueries/products/teleHab/teleHabPageQueries';
import { GQL_FETCH_VALDHUB_PAGE_ACCURATE_ENTRY, GQL_FETCH_VALDHUB_PAGE_BANNER_ASSETS_ENTRY, GQL_FETCH_VALDHUB_PAGE_BANNER_TEXT_ENTRY, GQL_FETCH_VALDHUB_PAGE_CENTRALIZED_ENTRY, GQL_FETCH_VALDHUB_PAGE_CONTROLS_ENTRY, GQL_FETCH_VALDHUB_PAGE_CTA_ENTRY, GQL_FETCH_VALDHUB_PAGE_CUSTOMIZABLE_ENTRY, GQL_FETCH_VALDHUB_PAGE_FULLY_FEATURED_ENTRY, GQL_FETCH_VALDHUB_PAGE_INTEGRATIONS_ENTRY, GQL_FETCH_VALDHUB_PAGE_NORMATIVE_DATA_ENTRY, GQL_FETCH_VALDHUB_PAGE_SHARING_CARING_ENTRY, GQL_FETCH_VALDHUB_RELATED_SYSTEMS_ENTRY } from './pageQueries/products/valdHub/valdHubPageQueries';


import {
  GQL_FETCH_MOVEHEALTH_PAGE_HERO_ENTRY,
  GQL_FETCH_MOVEHEALTH_PAGE_TELEHAB_NOW_MOVEHEALTH,
  GQL_FETCH_MOVEHEALTH_PAGE_EASY_ASSIGN,
  GQL_FETCH_MOVEHEALTH_PAGE_EXERCISES,
  GQL_FETCH_MOVEHEALTH_PAGE_PROGRAM_BUILD_ENTRY,
  GQL_FETCH_MOVEHEALTH_PAGE_QUESTIONAIRES,
  GQL_FETCH_MOVEHEALTH_PAGE_TOPICS,
  GQL_FETCH_MOVEHEALTH_PAGE_EDUCATION,
  GQL_FETCH_MOVEHEALTH_PAGE_PATIENTAPP,
  GQL_FETCH_PAGE_MOVEHEALTH_PAGE_CTA_ENTRY,
  GQL_FETCH_MOVEHEALTH_PAGE_RESULTS,
} from './pageQueries/products/movehealth/moveHealthPageQueries';

// =============================================
//                 LANDING PAGE QUERIES
// =============================================
export const GQL_FETCH_PAGE_METADATA = `
  query($pageId: String!) {
    page(id: $pageId, preview: ${IS_PREVIEW}) {
      seoMetadata {
        title
        description
        previewImage {
          url
        }
        index
        follow
      }
    }
  }
`;

export const GQL_FETCH_HOME_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.HOME_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_HOME_PAGE_HERO_ENTRY_ENTRY}
      ${GQL_FETCH_HOME_PAGE_SITE_OPTIONS_ENTRY}
      ${GQL_FETCH_HOME_PAGE_DATA_INFORMED_ENTRY}
      ${GQL_FETCH_HOME_PAGE_SOLUTIONS_ENTRY}
      ${GQL_FETCH_HOME_PAGE_IMAGE_GRID_ENTRY}
      ${GQL_FETCH_HOME_MEET_OUR_CLIENT_ENTRY}
      ${GQL_FETCH_HOME_PAGE_WE_EXIST_ENTRY}
      ${GQL_FETCH_HOME_PAGE_CLIENT_SUCCESS_ENTRY}
      ${GQL_FETCH_HOME_PAGE_CTA_ENTRY}
    }
    testimonials: testimonialCollection(where: {  categories: { slug: "home" }, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }) {
      items {
        name
        company
        title
        profileImage {
          url
          description
          width
          height
        }
        logo {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_SOLUTIONS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.SOLUTIONS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_SOLUTIONS_PAGE_HERO_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_VALD_HUB_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_CLIENT_SUCCESS_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_DATA_BEYOND_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_WE_EXIST_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_BETTER_MANAGEMENT_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_MEASUREMENT_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_NORMATIVE_DATA_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_PATIENTS_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_CLINICIANS_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_WE_EXIST_V2_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_LIKE_PROS_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_TESTIMONIAL_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_TESTIMONIALS_ENTRY}
      ${GQL_FETCH_SOLUTIONS_PAGE_CTA_ENTRY}
    }
    testimonials: testimonialCollection(where: {  categories: { slug: "solutions" }, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }) {
      items {
        name
        company
        title
        profileImage {
          url
          description
          width
          height
        }
        logo {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_CLINICIANS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.CLINICIANS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_CLINICIANS_PAGE_HERO_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_CLINICAL_SETTING_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_CLINICAL_SETTING_CAROUSEL_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_FOR_CLINICIANS_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_WE_EXIST_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_CLIENT_SUCCESS_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_MORE_TIME_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_TAILORED_CARE_ENTRY}
      ${GQL_FETCH_CLINICIANS_PAGE_CTA_ENTRY}
    }
    testimonials: testimonialCollection(where: {  categories: { slug: "clinicians" }, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }) {
      items {
        name
        company
        title
        profileImage {
          url
          description
          width
          height
        }
        logo {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_PATHOLOGIES_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.PATHOLOGIES_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_PATHOLOGIES_PAGE_HERO_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_ORTHOPAEDICS_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_CONVALESCE_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_PHONE_MOCKUPS_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_MSK_CONDITIONS_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_CARE_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_BENCHMARK_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_WE_EXIST_ENTRY}
      ${GQL_FETCH_PATHOLOGIES_PAGE_CLIENT_SUCCESS}
      ${GQL_FETCH_PATHOLOGIES_PAGE_CTA_ENTRY}
    }
    testimonials: testimonialCollection(where: {  categories: { slug: "clinicians" }, contentfulMetadata: { tags: { id_contains_some: ["vh"] } } }) {
      items {
        name
        company
        title
        profileImage {
          url
          description
          width
          height
        }
        logo {
          url
          description
          width
          height
        }
        bodyCopy {
          json
        }
      }
    }
  }
`;

export const GQL_FETCH_PRODUCTS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.PRODUCTS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
   
      }
      slug
      ${GQL_FETCH_PRODUCTS_PAGE_HERO_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_EFFECTIVE_CARE_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_MORE_TIME_FOR_PATIENTS_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_OBJECTIVE_MEASUREMENT_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_OBJECTIVE_MEASUREMENT_SOLUTIONS_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_BFR_CUFFS_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_NORMATIVE_DATA_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_DATA_POINTS_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_BETTER_COMMUNICATION_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_TOOLKIT_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_REPORTING_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_WE_EXIST_ENTRY}
      ${GQL_FETCH_PRODUCTS_PAGE_CLIENT_SUCCESS}
      ${GQL_FETCH_PRODUCTS_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_PRODUCTS_PAGE_RELATED_PRODUCTS_ENTRY}
    ${GQL_FETCH_PRODUCTS_PAGE_RELATED_PRODUCTS_2ND_ENTRY}
  }
`;

export const GQL_FETCH_AIRBANDS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.AIRBANDS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_AIRBANDS_PAGE_HERO_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_BFR_TRAINING_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_WHY_BFR_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_TAKEAWAY_GUESSWORK_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_CONVENIENCE_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_RELATED_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_FULLY_FEATURED_ENTRY}
      ${GQL_FETCH_AIRBANDS_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_AIRBANDS_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_DYNAMO_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.DYNAMO_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_DYNAMO_PAGE_BANNER_TEXT_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_TESTS_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_MODULARITY_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_ENGAGEMENT_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_USEABILITY_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_TEST_BATTERIES_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_VISIBILITY_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_TWO_MODELS_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_PLUS_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_LIGHT_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_DYNAMO_MAX_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_DYNAMO_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_DYNAMO_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_FORCEDECKS_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.FORCEDECKS_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_FORCEDECKS_PAGE_HERO_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_MADE_EASY_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_USEABILITY_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_VERSATILITY_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_BALANCE_ASSESSMENT_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_VISION_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_FIT_FOR_PURPOSE_ENTRY}
      ${GQL_FETCH_FD_VALIDATED_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_FORCEDECKS_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_FORCEDECKS_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_FORCEDECKS_RELATED_PRODUCTS_ENTRY}
  } 
`;

export const GQL_FETCH_FORCEFRAME_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.FORCEFRAME_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_FORCEFRAME_PAGE_BANNER_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_MODULARITY_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_FLEXIBILITY_PATIENTS_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_TEST_AND_TRAIN_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_FEATURE_IMAGE_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_FAQ_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_MAX_CARDS_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_FOLD_CARDS_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_FLEXIBLE_PAYMENT_PLAN_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_FORCEFRAME_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_FORCEFRAME_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_HUMANTRAK_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.HUMANTRAK_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_HUMANTRAK_PAGE_BANNER_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_ACCESSIBILITY_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_POWERFUL_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_CONTEXT_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_ENGAGEMENT_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_KIT_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_RELATED_ENTRY}
      ${GQL_FETCH_HUMANTRAK_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_HUMANTRAK_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_MSKED_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.MSKED_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_MSKED_PAGE_BANNER_ENTRY}
      ${GQL_FETCH_MSKED_PAGE_EDUCATION_DELIVERED_ENTRY}
      ${GQL_FETCH_MSKED_PAGE_EASY_TO_UNDERSTAND_ENTRY}
      ${GQL_FETCH_MSKED_PAGE_130_TOPIC_ENTRY}
      ${GQL_FETCH_MSKED_PAGE_PRESCRIBE_EDUCATIONAL_ENTRY}
      ${GQL_FETCH_MSKED_PAGE_VALD_SYSTEMS_ENTRY}
      ${GQL_FETCH_MSKED_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_MSKED_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_NORDBORD_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.NORDBORD_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_NORDBORD_PAGE_BANNER_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_GOLD_STANDARD_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_EASY_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_TEST_ECCENTRIC_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_USABILITY_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_FEATURES_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_FLEXIBILITY_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_NORDBORD_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_NORDBORD_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_SCOREIT_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.SCOREIT_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_SCOREIT_PAGE_HERO_ENTRY}
      ${GQL_FETCH_SCOREIT_PAGE_DIGITAL_PROMS_ENTRY}
      ${GQL_FETCH_SCOREIT_PAGE_CONVENIENT_ENTRY}
      ${GQL_FETCH_SCOREIT_PAGE_VALIDATED_ENTRY}
      ${GQL_FETCH_SCOREIT_PAGE_DELIVERED_ENTRY}
      ${GQL_FETCH_SCOREIT_PAGE_PROMS_LIBRARY_ENTRY}
      ${GQL_FETCH_SCOREIT_PRODUCT_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_SCOREIT_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_SCOREIT_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_SMARTSPEED_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.SMARTSPEED_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_SMARTSPEED_PAGE_HERO_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_SPEED_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_RELIABLE_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_PLUS_APP_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_WIDE_RANGE_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_UP_IN_LIGHTS_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_DATA_ACCESSORIES_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_SHOWCASE_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_GRID_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_DASH_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_ADDONS_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_SMART_JUMP_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_SMART_JUMP_ACCESSORIES_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_FLEXIBLE_ENTRY}
      ${GQL_FETCH_SMARTSPEED_PAGE_CTA_ENTRY}
    }
  }
`;

export const GQL_FETCH_TELEHAB_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.TELEHAB_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_TELEHAB_PAGE_HERO_ENTRY}
      ${GQL_FETCH_TELEHAB_PAGE_PRESCRIPTION_ENTRY}
      ${GQL_FETCH_TELEHAB_PAGE_EXERCISE_LIBRARY_ENTRY}
      ${GQL_FETCH_TELEHAB_PAGE_PROGRAM_BUILD_ENTRY}
      ${GQL_FETCH_TELEHAB_PAGE_MONITORING_ENTRY}
      ${GQL_FETCH_TELEHAB_PAGE_ENGAGEMENT_ENTRY}
      ${GQL_FETCH_TELEHAB_PAGE_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_PAGE_TELEHAB_PAGE_CTA_ENTRY}
    }
    ${GQL_FETCH_TELEHAB_PAGE_RELATED_PRODUCTS_ENTRY}
  }
`;

export const GQL_FETCH_MOVEHEALTH_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.MOVEHEALTH_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_MOVEHEALTH_PAGE_HERO_ENTRY}
      ${GQL_FETCH_MOVEHEALTH_PAGE_TELEHAB_NOW_MOVEHEALTH}
      ${GQL_FETCH_MOVEHEALTH_PAGE_EASY_ASSIGN}
      ${GQL_FETCH_MOVEHEALTH_PAGE_EXERCISES}
      ${GQL_FETCH_MOVEHEALTH_PAGE_PROGRAM_BUILD_ENTRY}
      ${GQL_FETCH_MOVEHEALTH_PAGE_QUESTIONAIRES}
      ${GQL_FETCH_MOVEHEALTH_PAGE_TOPICS}
      ${GQL_FETCH_MOVEHEALTH_PAGE_EDUCATION}
      ${GQL_FETCH_MOVEHEALTH_PAGE_PATIENTAPP}
      ${GQL_FETCH_MOVEHEALTH_PAGE_RESULTS}
      ${GQL_FETCH_PAGE_MOVEHEALTH_PAGE_CTA_ENTRY}
    }
  }
`;


export const GQL_FETCH_VALDHUB_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.VALDHUB_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
      ${GQL_FETCH_VALDHUB_PAGE_BANNER_TEXT_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_CENTRALIZED_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_FULLY_FEATURED_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_ACCURATE_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_CUSTOMIZABLE_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_SHARING_CARING_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_NORMATIVE_DATA_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_CONTROLS_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_INTEGRATIONS_ENTRY}
      ${GQL_FETCH_VALDHUB_RELATED_SYSTEMS_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_BANNER_ASSETS_ENTRY}
      ${GQL_FETCH_VALDHUB_PAGE_CTA_ENTRY}
    }
  }
`;

export const GQL_FETCH_CONTACT_PAGE_ENTRIES = `
  query {
    page(id: "${GQL_PAGE_ID_TYPES.CONTACT_LANDING_PAGE}", preview: ${IS_PREVIEW}) {
      sys {
        publishedAt
        firstPublishedAt
      }
      slug
    }
  }
`;

// =============================================
//             TOP NAVIGATION QUERY
// =============================================
export const GQL_FETCH_TOP_NAVIGATION_ENTRY = `
  query {
    topNavigation(id: "12yqsSK0wyRKe299E3BDt1", preview: ${IS_PREVIEW}) {
      logo {
        url
        width
        height
        description
      }
      navItemsCollection(order: order_ASC) {
        items {
          sys {
            id
          }
          slug
          pageName
          subPagesCollection(order: order_ASC) {
            items {
              sys {
                id
              }
              slug
              pageName
              description
            }
          }
        }
      }
    }
  }
`;

// =============================================
//              SITE MAPS QUERY
// =============================================
export const GQL_FETCH_SITEMAPS_DATA = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vh"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        sys {
          publishedAt
        }
        translationsCollection (preview: ${IS_PREVIEW}) {
          items {
            languageCode
            slug
          }
        }
      }
    }
    pageCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vh"], id_contains_none: ["translation"]} }}) {
      items {
        slug
        sys {
          publishedAt
        }
      }
    }
  }
`;

export const GQL_FETCH_LANDING_PAGE_METADATA = `
  query($pageId: String!) {
    page(id: $pageId, preview: ${IS_PREVIEW}) {
      seoMetadata {
        title
        description
        previewImage {
          url
        }
        index
        follow
      }
    }
  }
`;

// =============================================
//              BLOG QUERIES
// =============================================

export const GQL_GET_POST_METADATA_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vh"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        seoMetadata {
          ... on SeoMetaTags {
            title
            description
            previewImage {
              url
            }
            index
            follow
            canonical
          }
        }
      }
    }
  }
`;

export const GQL_GET_POST_ENTRY = `
  query ($slug: String!) {
    postCollection(
      where: { slug: $slug, contentfulMetadata: {tags: {id_contains_some: ["vh"]}} }
      limit: 1
      preview: ${IS_PREVIEW}
    ) {
      items {
        slug
        title
        categoriesCollection(limit: 1) {
          items {
            title
            slug
          }
        }
        thumbnail {
          url
        }
        excerpt
        sys {
          publishedAt
          firstPublishedAt
        }
        body {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                contentType
                description
              }
            }
            entries {
              __typename
              inline {
                sys {
                  id
                }
                __typename
                ... on Image {
                  title
                  alignment
                  width
                  url
                  media {
                    url
                    height
                    width
                    description
                  }
                }
                ... on BlogComponent {
                  componentType
                  mergedCellTable
                }
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on TextEditor {
                  tinyMce
                }
                ... on Button {
                  text
                  url
                  type
                  target
                }
                ... on Image {
                  media {
                    url
                    width
                    height
                    description
                  }
                  alignment
                  width
                  url
                  bodyCopy {
                    json
                  }
                }
                ... on Video {
                  type
                  url
                  description
                }
                ... on BlogComponent {
                  componentType
                  textBoxColor
                  mergedCellTable
                  bodyCopy {
                    json
                    links {
                      entries {
                        block {
                          sys {
                            id
                          }
                          ... on Image {
                            alignment
                            width
                            url
                            media {
                              url
                              description
                              width
                              height
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on DuplexComponent {
                  reversed
                  title
                  bodyCopy {
                    json
                  }
                  mediaPrimary {
                    url
                    width
                    height
                    description
                  }
                }
                ... on Grid {
                  sys {
                    id
                  }
                  carousel
                  scrollTo
                  title
                  bodyCopy {
                          json
                        }
                  topicCollection {
                    items {
                      ... on Card {
                        mediaPrimary {
                          width
                          height
                          url
                          description
                        }
                        mediaSecondary {
                          width
                          height
                          url
                          description
                        }
                        title
                        slug
                        bodyCopy {
                          json
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        datePublished
        featuredImage {
          url
          width
          height
          title
          description
          contentType
        }
        languageCode
        translationsCollection(limit: 10) {
          items {
            title
            slug
            languageCode
          }
        }
        linkedFrom {
          postCollection(limit: 10) {
            items {
              languageCode
              title
              slug
              translationsCollection(limit: 10) {
                items {
                  languageCode
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const GQL_FETCH_POST_ENTRY_SLUGS = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vh"]} }}) {
      items {
        slug
        languageCode
      }
    }
  }
`;

export const GQL_FETCH_TOTAL_POST_ENTRY = `
  query {
    postCollection(preview: ${IS_PREVIEW}, where: {contentfulMetadata: { tags: {id_contains_some: ["vh"]} }}) {
      total
    }
  }
`;

// =============================================
//             TOP ANNOUCEMENT BAR QUERY
// =============================================
export const GQL_FETCH_ANNOUNCEMENT_BAR = `
  query {
    eBooks: grid(id:"4Q16odoDkvwvjp6fGsmgv9"){
      title
      bodyCopy {
          json
        }
      topicCollection {
        items {
          ... on Card {
            link
            preTitle
            title
            bodyCopy {
              json
            }
          }
        }
      }
    }
  }
`;